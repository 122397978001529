import React from "react";

const Loader = ({ color }) => {
  const newcolor = color ? color : "#2D3D23";
  return (
    <div className="inset-0 flex items-center justify-center absolute top-0">
      <svg version="1.1" id="L4" viewBox="0 0 100 100" className="w-24 h-24">
        <circle fill={newcolor} stroke="none" cx="20" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={newcolor} stroke="none" cx="50" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={newcolor} stroke="none" cx="80" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
