import React, { useEffect, useState } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
// import ButtonContainer from "../../../containers/button";
import ErrorMessage from "../../../containers/errorMessage";
// import ContainerWithTitle from "../../../containers/title.container";
import CheckboxInput from "../../../containers/input.checkbox";
import ButtonContainer from "../../../containers/button";

const QuestionFour = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useGutContext();
  const [value, setValue] = useState([]);
  const [score, setScore] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value.length > 0) {
      // if (isConstipationSelected) {
      let nextPage = currentPage + 1;

      onNext(value);
      setCurrentPage(nextPage);
    } else {
      setErrorMessage("Please select at least one health goal.");
    }
  };
  console.log("score 12", score);

  const handleCheckboxChange = (event) => {
    const { value: checkboxValue, checked } = event.target;
    if (checkboxValue === "None of the above") {
      if (checked) {
        setErrorMessage(null);
        setValue([checkboxValue]);
        setScore(4);
      } else {
        setValue([]);
        setScore(0);
      }
    } else {
      if (checked) {
        setErrorMessage(null);
        setValue([...value, checkboxValue]);
        // Each checked item reduces score by 0.5
        const newScore = -(value.length + 1) * 0.5;
        setScore(newScore);
      } else {
        setValue(value.filter((item) => item !== checkboxValue));
        // Each unchecked item increases score by 0.5
        const newScore = -(value.length - 1) * 0.5;
        setScore(newScore);
      }
    }
  };

  const isCheckboxChecked = (checkboxValue) => {
    return value.includes(checkboxValue);
  };
  return (
    <ContainerWithTitle
      title="Have you experienced any of the following concerns in the last few months?"
      // noteTitle="Select as many options as needed"
      handleBack={onPrev}
      handleNext={handleSubmit}
      isContinueButton={value.length > 0 ? true : false}
    >
      <ul className="flex flex-col text-left">
        <CheckboxInput
          label="Feeling Heavy or bloated after having milk and milk products"
          value="Feeling Heavy or bloated after having milk and milk products"
          checked={isCheckboxChecked(
            "Feeling Heavy or bloated after having milk and milk products"
          )}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Feeling Heavy or bloated after having wheat and wheat products"
          value="Feeling Heavy or bloated after having wheat and wheat products"
          checked={isCheckboxChecked(
            "Feeling Heavy or bloated after having wheat and wheat products"
          )}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Recurring cold or fever"
          value="Recurring cold or fever"
          checked={isCheckboxChecked("Recurring cold or fever")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Constipation for more than 4 days in a week"
          value="Constipation for more than 4 days in a week"
          checked={isCheckboxChecked(
            "Constipation for more than 4 days in a week"
          )}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Bloating/Gas for more than 4 days in a week"
          value="Bloating/Gas for more than 4 days in a week"
          checked={isCheckboxChecked(
            "Bloating/Gas for more than 4 days in a week"
          )}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Tiredness or Low energy levels"
          value="Tiredness or Low energy levels"
          checked={isCheckboxChecked("Tiredness or Low energy levels")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Increase in weight and/or waist size "
          value="Increase in weight and/or waist size "
          checked={isCheckboxChecked("Increase in weight and/or waist size ")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="None of the above"
          value="None of the above"
          checked={isCheckboxChecked("None of the above")}
          onChange={handleCheckboxChange}
        />
      </ul>
    </ContainerWithTitle>
  );
};

export default QuestionFour;
