/* global gtag */
import React, { useEffect, useRef, useState, useCallback } from "react";
import Home from "./components/Home";
import QuestionTwo from "./components/Email";
import Exercise from "./components/exercise";
import QuestionFour from "./components/QuestionFour";
import QuestionFive from "./components/QuestionFive";
import QuestionSix from "./components/QuestionSix";
import LastPage from "./components/LastPage";
import "./App.css";
import Phyiscal_activity from "./components/phyiscal_activity";
import QuestionEight from "./components/QuestionEight";
import QuestionNine from "./components/QuestionNine";
import PhoneNumber from "./components/PhoneNumber";
import Name from "./components/Name";
import Email from "./components/Email";
import { FaArrowLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Age from "./components/age";
import Gender from "./components/gender";
import Weight from "./components/weight";
import Height from "./components/height";
import { useGutContext } from "./context/appContext";
import Questioneleven from "./components/questioneleven";
import QuestionTen from "./components/questionTen";
import Questiontwilve from "./components/questiontwilve";
import Question13 from "./components/Question13";
import axios from "axios";
// import Loader from "./containers/loader.js";
import SLoader from "./components/loader";
import { apiUrl } from "../../config/config.js";
import HealthAssessment from "./appUi";
// import OptVerification from "./containers/optVerification";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Loader from "./components/loader";
import OptVerification from "../../containers/optVerification.js";

function App() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [setShowNote] = useState(false);
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    currentTab,
    setCurrentTab,
    skipquestion,
    loading,
    setLoading,
    sessionValue,
    // isPhoneVerified,
    // setIsPhoneVerified,
  } = useGutContext();

  const validatePersonalInfo = () => {
    const newErrors = {};
    const nameRegex = /^[a-zA-Z\s]{2,50}$/;
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (!nameRegex.test(formData?.name)) {
      newErrors.name = "Please enter a valid full name";
    }
    if (!emailRegex.test(formData?.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!phoneRegex.test(formData?.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Allow only numbers for phone input
    const newValue = name === "phone" ? value.replace(/[^0-9]/g, "") : value;
    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };
  const handleOptionSelect = (category, value) => {
    if (category === "goals") {
      setFormData((prev) => ({
        ...prev,
        goals: prev.goals.includes(value)
          ? prev.goals.filter((g) => g !== value)
          : [...prev.goals, value],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [category]: value,
      }));
    }
  };
  // const progress = ((currentPage - 1) / (totalSteps - 1)) * 100;

  const totalSteps = 13; // Update this based on the total number of steps

  const progressPercentage = ((currentPage / totalSteps) * 100).toFixed(0);

  const [healthgoals, setHealthgoals] = useState(null);
  const [medicalcondition, setMedicationCondition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [IsQuizStarted, setIsQuizStarted] = useState(true);

  const payloadRef = useRef();

  useEffect(() => {
    payloadRef.current = payload;
  }, [payload]);

  // useEffect(() => {
  //   const hasValueIsTrue = () => {
  //     if (payload.health_goals) {
  //       return (
  //         payload.health_goals.includes("Bloating & Gas") ||
  //         payload.health_goals.includes("Constipation")
  //       );
  //     }
  //     return false;
  //   };

  //   const hasMedical_condition = () => payload.medical_condition === "Yes";
  //   const hasExercise = () => payload.exercise === "No";

  //   // console.warn("hasExercise", hasExercise());

  //   setHealthgoals(hasValueIsTrue());

  //   setPayload((prevData) => {
  //     let updatedData = { ...prevData };
  //     let needsUpdate = false;

  //     if (hasValueIsTrue() === false && prevData.gat_Symptom !== null) {
  //       updatedData.gat_Symptom = null;
  //       needsUpdate = true;
  //     } else if (
  //       hasMedical_condition() === false &&
  //       prevData.diagnosed_conditions.length > 0
  //     ) {
  //       updatedData.diagnosed_conditions = [];
  //       needsUpdate = true;
  //     } else if (
  //       hasExercise() === true &&
  //       prevData.phyiscal_activity !== "light"
  //     ) {
  //       updatedData.phyiscal_activity = "light";
  //       needsUpdate = true;
  //     }

  //     return needsUpdate ? updatedData : prevData;
  //   });
  // }, [payload.health_goals, payload.medical_condition, payload.exercise]);

  useEffect(() => {
    if (payload.exercise === "No") {
      setPayload((prevData) => ({ ...prevData, phyiscal_activity: "light" }));
    }
  }, [payload.exercise]);

  const [prevScore, setPrevScore] = useState(0);

  const HandleNext = (pageValue, pageName, score) => {
    setPayload((prevData) => ({
      ...prevData,
      [pageName]: pageValue,
    }));
    setCurrentPage((prevPage) => {
      // if (pageName === "medical_condition" && pageValue === "No") {
      //   return prevPage + 2;
      // } else if (pageName === "exercise" && pageValue === "No") {
      //   return prevPage + 2;
      // } else {
      return prevPage + 1;
      // }
    });

    const newPayload = {
      ...payload,
      [pageName]: pageValue,
    };

    // const data = {
    //   sessionId: sessionValue,
    //   formData: newPayload,
    // };

    // const config = {
    //   method: "post",
    //   url: `${apiUrl}/api/v1/session/update`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     console.log("Session updated successfully:", response.data);
    //   })
    //   .catch((error) => {
    //     console.error("Failed to update session:", error);
    //   });
  };

  console.log("currentScore payload.score", payload.score);
  const PrevHandler = () => {
    setCurrentPage((prevPage) => {
      if (healthgoals === false && prevPage === 7) {
        return prevPage - 2;
      } else if (
        payloadRef.current.medical_condition === "No" &&
        prevPage === 12
      ) {
        return prevPage - 2;
      } else if (payloadRef.current.exercise === "No" && prevPage === 9) {
        return prevPage - 2;
      } else {
        return prevPage - 1;
      }
    });
  };

  const FirstNextHandle = (e) => {
    e.preventDefault();
    if (!validatePersonalInfo()) {
      return;
    }
    setPayload((prevData) => ({
      ...prevData,
      name: formData?.name,
      email: formData?.email,
      phone: formData?.phone,
    }));
    setCurrentPage((prevPage) => prevPage + 1);
    setFormData({});

    const sessionCreate = async () => {
      try {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/api/v1/session/create`,
          headers: {},
        };

        const response = await axios.request(config);
        if (response.data.success === true) {
          const sessionId = response.data.sessionId;
          sessionStorage.setItem("quiz_Id", sessionId);
          updateSession(sessionId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const updateSession = async (sessionId) => {
      try {
        const newPayload = {
          ...payload,
          name: formData?.name,
          email: formData?.email,
          phone: formData?.phone,
        };
        const data = {
          sessionId: sessionId,
          formData: newPayload,
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/api/v1/session/update`,
          headers: {},
          data: data,
        };

        await axios.request(config);
        // Handle successful response
      } catch (error) {
        console.log(error);
      }
    };

    sessionCreate();
  };

  // //codeBy Shubham Sharma
  // useEffect(() => {
  //   const sessionCreate = async () => {
  //     try {
  //       const config = {
  //         method: "post",
  //         maxBodyLength: Infinity,
  //         url: `${apiUrl}/api/v1/session/create`,
  //         headers: {},
  //       };

  //       const response = await axios.request(config);
  //       if (response.data.success === true) {
  //         const sessionId = response.data.sessionId;
  //         sessionStorage.setItem("quiz_Id", sessionId);
  //         // updateSession(sessionId);
  //       }
  //       return sessionStorage
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   const updateSession = async (updateData, sessionId) => {

  //     console.log("sessionId 315",sessionId);
  //     try {
  //       const newPayload = {
  //         ...payload,
  //         name: updateData?.name,
  //         email: updateData?.email,
  //         phone: updateData?.phone,
  //       };
  //       const data = {
  //         sessionId: sessionId,
  //         formData: newPayload,
  //       };

  //       const config = {
  //         method: "post",
  //         maxBodyLength: Infinity,
  //         url: `${apiUrl}/api/v1/session/update`,
  //         headers: {},
  //         data: data,
  //       };

  //       await axios.request(config);
  //       // Handle successful response
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   const urlParams = new URLSearchParams(window.location.search);
  //   const name = urlParams.get("name");
  //   const email = urlParams.get("email");
  //   const phone = urlParams.get("phone");

  //   if (name && email && phone) {
  //     let sessionId = await sessionCreate();
  //     let payload = {name: name, email: email, phone: phone}
  //     // setFormData({ name, email, phone });
  //     // HandleNext(1, "age");
  //     setCurrentPage(1);
  //     setIsQuizStarted(false);
  //     console.log("sessionId",sessionId);
  //     updateSession(payload ,sessionId);
  //   }
  // }, []);

  // Code by Shubham Sharma

  const sessionCreate = async () => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/session/create`,
        headers: {},
      };

      const response = await axios.request(config);
      if (response.data.success === true) {
        const sessionId = response.data.sessionId;
        sessionStorage.setItem("quiz_Id", sessionId);
        return sessionId;
      }
    } catch (error) {
      console.error("Error creating session:", error);
    }
    return null; // Return null if session creation fails
  };

  const updateSession = async (updateData, sessionId) => {
    if (!sessionId) {
      console.error("Session ID is required to update session.");
      return;
    }

    try {
      // const newPayload = {
      //   name: updateData?.name,
      //   email: updateData?.email,
      //   phone: updateData?.phone,
      // };
      const newPayload = {
        ...payload,
        name: updateData?.name,
        email: updateData?.email,
        phone: updateData?.phone,
      };

      const data = {
        sessionId: sessionId,
        formData: newPayload,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/session/update`,
        headers: {},
        data: data,
      };

      await axios.request(config);
      console.log("Session updated successfully.");
    } catch (error) {
      console.error("Error updating session:", error);
    }
  };

  useEffect(() => {
    const initializeSession = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const name = urlParams.get("name");
      const email = urlParams.get("email");
      const phone = urlParams.get("phone");

      if (name && email && phone) {
        const sessionId = await sessionCreate();
        if (sessionId) {
          const payload = { name, email, phone };

          setPayload((prevData) => ({
            ...prevData,
            ["name"]: name,
            ["email"]: email,
            ["phone"]: phone,
          }));
          await updateSession(payload, sessionId);
          setCurrentPage(1);
          setIsQuizStarted(false);
          console.log("Session ID:", sessionId);
        } else {
          console.error("Failed to create session.");
        }
      }
    };

    initializeSession();
  }, []);

  console.log("page ==>", currentPage);
  console.log("formdata ==>", formData);
  console.log("IsQuizStarted ==>", IsQuizStarted);
  console.log("payload ==>", payload);

  // console.warn("==>", currentPage);
  console.log("==>", payload);

  const FinishHandler = () => {
    const sesssionPayload = {
      sessionId: sessionValue,
    };
    try {
      setLoading(true);
      setIsLoading(true);
      // let sessionConfig = {
      //   method: "delete",
      //   maxBodyLength: Infinity,
      //   url: `${apiUrl}/api/v1/session/delete`,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: JSON.stringify(sesssionPayload),
      // };

      // axios.request(sessionConfig).then((response) => {
      //   console.log(JSON.stringify("Session Delete", response.data));
      //   sessionStorage.removeItem("quiz_Id");
      // });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/gut/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify("response.data", response.data.data._id));
          setIsLoading(true);
          if (response.data.success === true && response.data?.data._id) {
            window.location.href = `https://thehealthspanco.com/pages/gut-report?healthspanid=${response.data?.data?._id}`;
            setCurrentPage(0);
            setPayload([]);
          } else {
            setLoading(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setIsLoading(false);

          console.log(error);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const [isOtpSended, setIsOtpSended] = useState("");
  const [showOpt, setShowOpt] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const [optLoader, setOptLoader] = useState(false);
  const OtpHandler = (e) => {
    e.preventDefault();
    if (!isOtpVerified) {
      setOptLoader(true);
      if (formData.phone.length === 10) {
        let data = JSON.stringify({
          phone: formData?.phone,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiUrl}/api/v1/whatsapp/sendotp`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data.success) {
              // setTimeout(() => {
              setIsOtpSended(true);
              setShowOpt(true);
              setOptLoader(false);
              // }, 4000);
            } else {
              setOptLoader(false);
              setIsOtpSended(false);
            }
          })
          .catch((error) => {
            setOptLoader(false);
            console.log(error);
          });
      }
    }
  };

  const Header = () => (
    <div className="flex  w-full  justify-center items-center relative py-3  bg-[#2d3d23] text-white">
      <div className="w-11/12 relative gap-2  flex items-center justify-center ">
        {/* logo */}
        <img
          src={`https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=240`}
          className={` w-24 lg:w-32   brightness-[15000%] cursor-pointer`}
          onClick={() => {
            window.location.href = "https://thehealthspanco.com/";
          }}
        />
        {/* <div className=' text-sm lg:text-base'>Weight & Waist Assessment.</div> */}
      </div>
    </div>
  );

  console.log("payload.score", payload.score, prevScore);
  return !IsQuizStarted ? (
    <div className="App gap-10 ">
      {isLoading && <Loader />}
      {/* {!isOtpSended && ( */}
      {showOpt && (
        <div className="fixed w-full h-full z-50 flex justify-center items-center">
          <div className=" absolute w-full h-full backdrop-blur"></div>

          <div
            class="p-4 fixed top-1/2 z-50 left-1/2 -translate-x-1/2 -translate-y-1/2 mb-4 text-sm w-11/12 md:w-5/12 max-w-2xl flex flex-col justify-center items-center bg-white border-t-4 border-[#2F412F] drop-shadow-lg shadow-lg rounded-lg "
            role="alert"
          >
            <div className="text-[#2F412F] text-sm font-bold mb-2">
              The HealthSpan Co.
            </div>
            <div className="font-bold text-[#2F412F] text-3xl mb-10">
              Opt Verification
            </div>
            <OptVerification
              formData={formData}
              setShowOpt={setShowOpt}
              setIsOtpVerified={setIsOtpVerified}
              OtpHandler={OtpHandler}
            />
          </div>
        </div>
      )}
      {/* )} */}

      <div className="h-screen relative w-full bg-gray-100 overflow-y-auto ">
        {/* Header */}
        <header className="bg-[#2F412F] sticky h-24 top-0 z-50 p-5 flex justify-between items-center">
          <div className="text-white text-lg md:text-2xl font-bold">
            The HealthSpan Co.
          </div>
          <button
            onClick={() => {
              setCurrentPage(0);
              setPayload("");
              window.location.href = "https://thehealthspanco.com/";
            }}
            className="bg-white px-4 text-xs md:text-base p-1 md:py-2 rounded text-red-600 no-underline"
          >
            Exit
          </button>
        </header>

        <div className="max-w-4xl mx-auto p-5 h-[calc(100%-6rem)]  ">
          {/* Progress Bar */}
          <div className="my-5">
            <div className="h-1.5 bg-gray-200 rounded overflow-hidden">
              <div
                className="h-full bg-[#FFB74D] transition-all duration-500"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
            <div className="flex gap-1.5 md:gap-2.5 mt-2.5 overflow-auto">
              {[
                "Basic Info",
                "Lifestyle",
                "Symptoms",
                "Genetics",
                // window.innerWidth > 768 ? "Diet & Health" : "Diet",
                "Results",
              ]
                .filter((step, index) => window.innerWidth > 768 || index !== 4)
                .map((step, index) => (
                  <div
                    key={step}
                    className={`flex-1 p-2 md:p-4 text-xs text-nowrap md:text-base text-center rounded-lg cursor-pointer transition-all
                  ${
                    (currentPage <= 4 && step === "Basic Info") ||
                    (currentPage >= 5 &&
                      currentPage <= 6 &&
                      step === "Lifestyle") ||
                    (currentPage === 12 &&
                      currentPage <= 19 &&
                      step === "Genetics") ||
                    (currentPage >= 7 &&
                      currentPage <= 10 &&
                      step === "Symptoms") ||
                    (currentPage === 11 && step === "Lifestyle") ||
                    (currentPage === 13 && step === "Results")
                      ? "bg-[#2F412F] text-white"
                      : "bg-white"
                  }`}
                  >
                    {step}
                  </div>
                ))}
            </div>
          </div>
          {currentPage === 0 && (
            <form className="h-full">
              <div className="bg-white rounded-xl p-8 shadow-md">
                <div className="animate-fadeIn text-start">
                  <Heading
                    title="Let's Get Started"
                    description="Tell us a bit about yourself"
                  />

                  <div className="space-y-6">
                    <InputField
                      label="Full Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={errors.name}
                    />

                    <InputField
                      label="Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      error={errors.email}
                    />

                    <div>
                      <div className="flex items-start  gap-2 w-full">
                        <div className="w-full flex flex-col items-start">
                          <InputField
                            label="Mobile Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            className="w-full"
                            disabled={isOtpVerified}
                            maxLength={10}
                          />
                          <div className="hidden sm:text-sm mt-2 sm:flex items-center gap-2 bg-yellow-50 text-yellow-600 rounded px-2">
                            <AiOutlineInfoCircle className=" text-xs sm:text-base" />
                            <span>
                              Hi, we'll whatsapp you an OTP right away, to be
                              sure that BOTs don't take our assessment.
                            </span>
                          </div>
                        </div>

                        <div
                          className={`text-nowrap border sm:mt-7 mt-5 sm:py-3 sm:px-3 py-2 px-2 rounded-lg  ${
                            isOtpVerified
                              ? "bg-transparent text-[#2F412F]"
                              : "border-2 border-[#2F412F] text-[#2F412F] cursor-pointer"
                          }`}
                          onClick={formData.phone && OtpHandler}
                        >
                          {isOtpVerified ? (
                            "Verified"
                          ) : (
                            <span className="relative text-sm">
                              <span
                                className={`${
                                  optLoader ? "opacity-100" : "opacity-0"
                                } absolute w-10/12 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10`}
                              >
                                <SLoader />
                              </span>
                              <span
                                className={`${
                                  optLoader ? "opacity-0" : "opacity-100"
                                }`}
                              >
                                Send Otp
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="text-[0.59rem] sm:text-sm mt-2 sm:hidden flex items-center gap-2 bg-yellow-50 text-yellow-600 rounded px-2">
                        <AiOutlineInfoCircle className=" text-sm sm:text-base" />
                        <span>
                          Hi, we'll whatsapp you an OTP right away, to be sure
                          that BOTs don't take our assessment.
                        </span>
                      </div> */}
                    </div>
                  </div>

                  <NoteContainer
                    message="Your information is protected and will never be shared."
                    title="Privacy Guaranteed"
                  />
                </div>
              </div>
              <div className="flex justify-start mt-8 pb-10 w-full text-xs md:text-base">
                {isOtpVerified &&
                  (formData.name || formData.email || formData.phone) && (
                    <button
                      onClick={FirstNextHandle}
                      type="submit"
                      className="px-6 py-3 bg-[#2F412F] text-white rounded-lg font-semibold ml-auto hover:bg-[#3D533D] transition-colors"
                    >
                      Continue
                    </button>
                  )}
              </div>
            </form>
          )}
          {currentPage === 1 && (
            <Age
              onNext={(data) => HandleNext(data, "age")}
              onPrev={() => {
                PrevHandler();
                setIsOtpVerified(false);
              }}
            />
          )}
          {currentPage === 2 && (
            <Gender
              onNext={(data) => HandleNext(data, "gender")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 3 && (
            <Weight
              onNext={(data) => HandleNext(data, "weight")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 4 && (
            <Height
              onNext={(data) => HandleNext(data, "height")}
              onPrev={PrevHandler}
              // onClick={SessionUpdateHandler}
            />
          )}
          {currentPage === 5 && (
            <Exercise
              onNext={(data) => HandleNext(data, "exercise")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 6 && (
            <Phyiscal_activity
              onNext={(data) => HandleNext(data, "phyiscal_activity")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 7 && (
            <QuestionFour
              onNext={(data) => HandleNext(data, "concerns")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 8 && (
            <QuestionFive
              onNext={(data) => HandleNext(data, "pass_stoll")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 9 && (
            <QuestionEight
              onNext={(data) => HandleNext(data, "bst")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 10 && (
            <QuestionNine
              onNext={(data) => HandleNext(data, "bothered")}
              onPrev={PrevHandler}
            />
          )}
          {currentPage === 11 && (
            <Question13
              onNext={(data) => HandleNext(data, "medication")}
              onPrev={PrevHandler}
            />
          )}
          {/* {currentPage === 11 && (
            <QuestionTen
              onNext={(data) => HandleNext(data, "medication")}
              onPrev={PrevHandler}
            />
          )} */}
          {currentPage === 12 && (
            <Questioneleven
              onNext={(data) => HandleNext(data, "history_of_obesity")}
              onPrev={PrevHandler}
            />
          )}
          {/* {currentPage === 13 && (
            <Questiontwilve
              onNext={(data) => HandleNext(data, "allergic")}
              onPrev={PrevHandler}
            />
          )} */}
          {/* {currentPage === 14 && (
            <Question13
              onNext={(data) => HandleNext(data, "medication")}
              onPrev={PrevHandler}
            />
          )} */}
          {currentPage === 13 && (
            <LastPage onFinish={(data) => FinishHandler(data)} />
            // <LastPage onFinish={() => console.log("Clicked")} />
          )}
        </div>
        {/* <div class="fixed bottom-4 left-4 z-50">
          <a
            href="https://api.whatsapp.com/send/?phone=918431335947&text=Hi%2C%0AI%27m+interested+in+learning+more+about+your+health+products+and+weight+loss+plan.&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
            class="block w-16 h-16 md:w-20 md:h-20"
          >
            <img
              src="/whatsappLogo.png"
              alt="Chat on WhatsApp"
              class="w-full h-full object-contain"
            />
          </a>
        </div> */}
      </div>
      {/* <HealthAssessment /> */}

      {/* {currentPage === 0 && (
        <Name onNext={(data) => HandleNext(data, "name")} />
      )}

      

      {currentPage === 1 && (
        <PhoneNumber onNext={(data) => HandleNext(data, "phone")} />
      )}
      {currentPage === 2 && (
        <Email
          onNext={(data) => HandleNext(data, "email")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 3 && <Age onNext={(data) => HandleNext(data, "age")} />}
      {currentPage === 4 && (
        <Gender onNext={(data) => HandleNext(data, "gender")} />
      )}
      {currentPage === 5 && (
        <Weight onNext={(data) => HandleNext(data, "weight")} />
      )}
      {currentPage === 6 && (
        <Height
          onNext={(data) => HandleNext(data, "height")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 7 && (
        <Health_goals onNext={(data) => HandleNext(data, "health_goals")} />
      )}
      {currentPage === 8 && (
        <QuestionFour onNext={(data) => HandleNext(data, "gat_Symptom")} />
      )}
      {currentPage === 9 && (
        <QuestionFive onNext={(data) => HandleNext(data, "exercise")} />
      )}
      {currentPage === 10 && (
        <QuestionSeven
          onNext={(data) => HandleNext(data, "phyiscal_activity")}
        />
      )}
      {currentPage === 11 && (
        <QuestionEight onNext={(data) => HandleNext(data, "sleep")} />
      )}
      {currentPage === 12 && (
        <QuestionNine
          onNext={(data) => HandleNext(data, "medical_condition")}
        />
      )}
      {currentPage === 13 && (
        <QuestionTen
          onNext={(data) => HandleNext(data, "diagnosed_conditions")}
        />
      )}
      {currentPage === 14 && (
        <Questioneleven onNext={(data) => HandleNext(data, "eating_habits")} />
      )}
      {currentPage === 15 && (
        <Questiontwilve onNext={(data) => HandleNext(data, "allergic")} />
      )}
      {currentPage === 16 && (
        <Question13 onNext={(data) => HandleNext(data, "medication")} />
      )}
      {currentPage === 17 && (
        <LastPage onFinish={(data) => FinishHandler(data)} />
      )} */}
    </div>
  ) : (
    <LandingPage setIsQuizStarted={setIsQuizStarted} Header={Header} />
  );
}

export default App;

export const InputField = ({
  label,
  name,
  value,
  onChange,
  error,
  className,
  ...props
}) => {
  return (
    <div className="w-full">
      <label className="block mb-1 text-xs md:text-base font-medium text-zinc-700">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full p-2.5 text-sm md:text-lg px-5 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-[#2F412F] ${className}`}
        placeholder={`Enter your ${label}`}
        {...props}
      />
      {error && <span className="text-red-600 text-sm mt-1">{error}</span>}
    </div>
  );
};

export const Heading = ({ title, description }) => {
  return (
    <div className="text-start">
      <h2 className="text-lg md:text-2xl font-bold md:mb-2">{title}</h2>
      <p className="text-gray-600 mb-6 text-xs md:text-base">{description}</p>
    </div>
  );
};

const CheckContainer = ({ goal, formData, handleOptionSelect, category }) => {
  return (
    <div
      key={goal.id}
      onClick={() => handleOptionSelect(category, goal.id)}
      className={`p-5 rounded-lg cursor-pointer transition-all text-start
                      ${
                        formData[category]?.includes(goal.id)
                          ? "bg-[#3D533D] text-white border-2 border-transparent"
                          : "bg-gray-100 hover:border-[#2F412F] border-2 border-transparent"
                      }`}
    >
      <div className="font-semibold mb-1 md:mb-3 text-sm md:text-lg">
        {goal.title}
      </div>
      <div className="text-xs md:text-base opacity-90 ">{goal.desc}</div>
    </div>
  );
};

const NoteContainer = ({ message, title }) => {
  return (
    <div className="bg-orange-50 border-l-4 border-[#FFB74D] p-4 rounded mt-6 text-xs md:text-lg text-start">
      <strong className="">{title}:</strong> {message}
    </div>
  );
};

const LandingPage = ({ setIsQuizStarted, Header }) => {
  const quizStarted = () => {
    window.dataLayer.push({
      event: "assesment_start",
      event_label: "start_quiz_button",
      value: "quiz started",
    });
    console.log("window", window.dataLayer);
  };
  return (
    <>
      <div className="fixed bottom-4 left-4 z-50">
        <a
          href="https://api.whatsapp.com/send/?phone=918431335947&text=Hi%2C%0AI%27m+interested+in+learning+more+about+your+health+products+and+weight+loss+plan.&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          class="block w-16 h-16 md:w-20 md:h-20"
        >
          <img
            src="/whatsappLogo.png"
            alt="Chat on WhatsApp"
            class="w-full h-full object-contain"
          />
        </a>
      </div>
      <div className="h-screen flex flex-col items-center  relative  text-center w-full backdrop-blur-lg ">
        <Header />
        <div className="container md:w-[70%] p-4 bg-white">
          <header className="bg-gradient-to-r p-6 bg-[#2d3d23] text-white text-center py-12 rounded-b-2xl shadow-lg">
            <h1 className="text-3xl md:text-5xl font-bold mb-4">
              Discover Your Path to Optimal Gut Health
            </h1>
            <p className="text-md md:text-xl opacity-90">
              Take our science-backed 2-minute quiz to uncover your gut health
              score and get personalized recommendations
            </p>
          </header>

          <main>
            <div className="flex flex-col md:flex-row justify-center gap-8 my-8">
              <div className="text-center">
                <div className="text-4xl font-bold text-[#2d3d23]">25,000+</div>
                <div>Success Stories</div>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold text-[#2d3d23]">92%</div>
                <div>Improvement Rate</div>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold text-[#2d3d23]">2 min</div>
                <div>Quick Assessment</div>
              </div>
            </div>

            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 my-8">
              {[
                {
                  imgSrc: "/images/gutLanding/1.png",
                  title: "Step 1",
                  text: "Take the <strong>2-minute</strong> Gut Health Quiz",
                },
                {
                  imgSrc: "/images/gutLanding/2.png",
                  title: "Step 2",
                  text: "Know the <strong>root cause</strong> of your gut issues and get your <strong>gut health score</strong>",
                },
                {
                  imgSrc: "/images/gutLanding/3.png",
                  title: "Step 3",
                  text: "Get <strong>personalised</strong> product recommendations",
                },
                {
                  imgSrc: "/images/gutLanding/4.png",
                  title: "Step 4",
                  text: "Get a <strong>10-minute</strong> free consultation",
                },
              ].map((benefit, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow transition-transform transform hover:translate-y-1"
                >
                  <img
                    src={benefit.imgSrc}
                    alt={`Benefit ${index + 1}`}
                    className="h-56 w-full object-cover rounded-lg"
                  />
                  <div className="text-center mt-4 md:mt-8">
                    <h4 className="font-semibold mb-4 md:mb-7">
                      {benefit.title}
                    </h4>
                    <p dangerouslySetInnerHTML={{ __html: benefit.text }}></p>
                  </div>
                </div>
              ))}
            </section>

            <div
              className="sticky bottom-5 text-center mb-8 cursor-pointer inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-sm md:text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"
              // onClick={() => { setIsQuizStarted(false); quizStarted(); }}
              onClick={() => {
                setIsQuizStarted(false);
                gtag("event", "start_assessment_click", {
                  event_category: "engagement",
                  event_label: "start_your_free_assessment_button",
                  value: 1,
                });
              }}
            >
              {/* <a href="https://assessment.thehealthspanco.com" className="inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"> */}
              Start Your Free Assessment
              {/* </a> */}
            </div>

            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
              {[
                {
                  imgSrc: "/images/gutLanding/g1.png",
                  name: "Namita, 33",
                  testimonial:
                    "I tried Bloat Relief Digestive Mix because I used to feel bloated & heavy after having milk. I had 1 sachet each day for 6 weeks & i feel much lighter now.",
                },
                {
                  imgSrc: "/images/gutLanding/g2.png",
                  name: "Tushar, 36",
                  testimonial:
                    "I had the Gut Repair Digestive Mix for 2 months and it has helped me a lot in reducing constipation. And now I sleep better too",
                },
                {
                  imgSrc: "/images/gutLanding/g3.png",
                  name: "Sangeeta, 40",
                  testimonial:
                    "The Sustainably Lean Metabolism Mix dissolves easily in water and it has brought down my sweet cravings, Very Delicious and refreshing! I've lost 4 Kgs in 6 weeks.",
                },
              ].map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white flex flex-col gap-3 text-center items-center md:items-start md:text-start p-6 rounded-lg shadow transition-transform transform hover:translate-y-1"
                >
                  <img
                    src={testimonial.imgSrc}
                    alt={testimonial.name}
                    className="w-20 h-20 rounded-full  mb-4"
                  />
                  <p className="font-semibold ">{testimonial.name}</p>
                  <p className="text-md">{testimonial.testimonial}</p>
                </div>
              ))}
            </section>
          </main>

          <footer className="bg-gradient-to-r bg-[#2d3d23]  text-white text-center p-6 mt-12 flex flex-col items-center gap-2">
            <h2 className="text-2xl font-bold">
              Ready to Transform Your Gut Health?
            </h2>
            <p className="my-2">
              Join thousands who've discovered their path to optimal digestive
              wellness
            </p>
            <div
              // onClick={() => setIsQuizStarted(false)}
              onClick={() => {
                setIsQuizStarted(false);
                gtag("event", "start_assessment_click", {
                  event_category: "engagement",
                  event_label: "start_your_free_assessment_button",
                  value: 1,
                });
              }}
              className="inline-block bg-orange-600 text-white py-3 px-6 rounded-full text-sm cursor-pointer md:text-md sticky md:relative bottom-5 md:bottom-0 md:text-lg font-semibold shadow-md transition-transform transform hover:translate-y-1"
            >
              Take the Free Quiz Now
            </div>
            <div className="flex relative justify-center gap-2 mt-4 opacity-90 text-sm">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                className="w-4 h-5 absolute top-0 -left-1 md:-left-5"
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
              </svg>
              <span>
                Your information is protected by industry-leading encryption
              </span>
            </div>
          </footer>
        </div>

        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
      </div>
    </>
  );
};
