import React from "react";
import Weight_Main from "../pages/weightQuiz/main";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Guts_Main from "../pages/gutQuiz/main";
import NotFound from "../pages/notFound";

const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Screen />} /> */}
      <Route path="/" element={<Navigate to="/weight" />} />
      <Route path="/weight" element={<Weight_Main />} />
      <Route path="/gut" element={<Guts_Main />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;

const Screen = () => (
  <div className="h-screen flex justify-center items-center text-sm space-x-3">
    <Link to="/weight">
      <button className="border p-2 px-5 rounded-md shadow-lg hover:bg-gradient-to-br from-green-800 hover:text-white">
        Weight Quiz
      </button>
    </Link>
    <Link to="/gut">
      <button className="border p-2 px-5 rounded-md shadow-lg hover:bg-gradient-to-br from-green-800 hover:text-white">
        Gut Quiz
      </button>
    </Link>
  </div>
);
