import React, { useEffect, useState } from "react";
import { useGutContext } from "../context/appContext";
import ContainerWithTitle from "../../../containers/title.container";
import CheckboxInput from "../../../containers/input.checkbox";
import ButtonContainer from "../../../containers/button";
import ErrorMessage from "../../../containers/errorMessage";
// import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
// import ContainerWithTitle from "../../../containers/title.container";
// import ButtonContainer from "../../../containers/button";

const Exercise = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useGutContext();
  const [value, setValue] = useState([]);
  // const [, setErrorMessage] = useState(null);

  useEffect(() => {
    setValue(payload?.health_goals || "");
  }, [currentPage, payload]);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (value.length > 0) {
  //     const isConstipationSelected =
  //       value.includes("Constipation") || value.includes("Bloating & Gas");
  //     let nextPage = currentPage + 2;
  //     if (isConstipationSelected) {
  //       nextPage = currentPage + 1;
  //     }
  //     onNext(value);
  //     setCurrentPage(nextPage);
  //   } else {
  //     setErrorMessage("Please select at least one health goal.");
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage(
        "Please select how often you are bothered by gut symptoms."
      );
    }
  };

  // const handleCheckboxChange = (event) => {
  //   const { value: checkboxValue, checked } = event.target;
  //   if (checked) {
  //     setErrorMessage(null);
  //     setValue([...value, checkboxValue]);
  //   } else {
  //     setValue(value.filter((item) => item !== checkboxValue));
  //   }
  // };
  const handleRadioChange = (event, score) => {
    setValue(event.target.value);
    onNext(event.target.value);
  };

  const isCheckboxChecked = (checkboxValue) => {
    return value.includes(checkboxValue);
  };

  return (
    //     <ContainerWithTitle
    //       title="In addition to weight loss, what are your other health goals?"
    //       handleNext={handleSubmit}
    //       handleBack={onPrev}
    //       isContinueButton={value.length > 0 ? true : false}
    //       // {value? true}
    //       // Apply "&&" for Change the line,
    //       message="Great! We're excited to partner with you to hit your goals. &&
    // Weight loss is an important goal, but The HealthSpan Co.’s mission is helping people achieve their fitness goals, whatever that might be. "
    //     >
    //       <ul className="flex flex-col text-left">
    //         <CheckboxInput
    //           label="Inch Loss"
    //           value="Inch Loss"
    //           checked={isCheckboxChecked("Inch Loss")}
    //           onChange={handleCheckboxChange}
    //         />
    //         <CheckboxInput
    //           label="Reduce Belly Fat"
    //           value="Reduce Belly Fat"
    //           checked={isCheckboxChecked("Reduce Belly Fat")}
    //           onChange={handleCheckboxChange}
    //         />
    //         <CheckboxInput
    //           label="Increased Energy Levels"
    //           value="Increased Energy Levels"
    //           checked={isCheckboxChecked("Increased Energy Levels")}
    //           onChange={handleCheckboxChange}
    //         />
    //         <CheckboxInput
    //           label="Bloating & Gas"
    //           value="Bloating & Gas"
    //           checked={isCheckboxChecked("Bloating & Gas")}
    //           onChange={handleCheckboxChange}
    //         />
    //         <CheckboxInput
    //           label="Constipation"
    //           value="Constipation"
    //           checked={isCheckboxChecked("Constipation")}
    //           onChange={handleCheckboxChange}
    //         />
    //       </ul>

    //       {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    //     </ContainerWithTitle>

    <ContainerWithTitle
      title="Do you exercise regularly?"
      handleBack={onPrev}
      isContinueButton={false}
      description="More than 3 days a week for 30 min daily atleast."
    >
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          colorChecked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          colorChecked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Exercise;
