// CheckboxInput.js
import React from "react";

const CheckboxInput = ({ label, value, checked, onChange }) => {
  return (
    <label
      className={`${
        checked ? "bg-[#2d3d23] text-white " : ""
      }   text-left  py-4 px-5 my-1 rounded border flex items-center gap-3 lg:gap-6`}
    >
      <input
        type="checkbox"
        name="symptom"
        value={value}
        checked={checked}
        onChange={onChange}
        className="w-3 lg:w-4 h-3 lg:h-4 accent-[#2d3d23] border rounded-lg border-green-900"
      />

      {/* <div className="checkbox-text capitalize" >{label}</div> */}
      <div className=" mb-1 md:mb-1 text-sm md:text-lg">{label}</div>
      {/* <div className="text-xs md:text-base opacity-90 ">{goal.desc}</div> */}
    </label>
  );
};

export default CheckboxInput;
