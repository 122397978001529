import React, { useRef, useState, useEffect } from "react";
import { apiUrl } from "../config/config";
import axios from "axios";
// import Loader from "./loader";
import {CircleLoader} from "./loader";

export default function OptVerification({
  formData,
  setShowOpt,
  setIsOtpVerified,
  OtpHandler,
}) {
  // const { isPhoneVerified, setIsPhoneVerified } = useWeightContext();
  const [otp, setOtp] = useState(Array(4).fill(""));
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(59); // Timer for 59 seconds
  const [canResend, setCanResend] = useState(false); // Resend button state

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer((prev) => prev - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setCanResend(true); // Enable the resend button after timer ends
    }
  }, [timer]);

  const handleKeyDown = (e) => {
    const index = inputRefs.current.indexOf(e.target);
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      setOtp((prevOtp) => {
        const updatedOtp = [...prevOtp];
        updatedOtp[index] = "";
        return updatedOtp;
      });
      if (e.key === "Backspace" && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleInput = (e) => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target);
    if (/^[0-9]$/.test(target.value)) {
      setOtp((prevOtp) => {
        const updatedOtp = [...prevOtp];
        updatedOtp[index] = target.value;
        return updatedOtp;
      });
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (e) => e.target.select();

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setOtp(digits);
    digits.forEach((digit, idx) => {
      if (inputRefs.current[idx]) {
        inputRefs.current[idx].value = digit;
      }
    });
    if (digits.length < otp.length) {
      inputRefs.current[digits.length].focus();
    }
  };

  const VerifyHandler = () => {
    setLoading(true);
    const data = JSON.stringify({ otp: otp.join("") });
    const config = {
      method: "post",
      url: `${apiUrl}/api/v1/whatsapp/verifyotp/${formData?.phone}`,
      headers: { "Content-Type": "application/json" },
      data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success) {
          setShowOpt(false);
          setIsOtpVerified(true);
          setMessage("");
        } else {
          setMessage(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setIsOtpVerified(false);
        setLoading(false);

        // Check if there's a response and retrieve the error message
        if (err.response && err.response.data) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Something went wrong. Please try again.");
        }
      });
  };

  const handleResend = (e) => {
    setCanResend(false);
    setMessage("");
    OtpHandler(e);
    setTimer(59);
    setOtp(Array(4).fill(""));
  };

  return (
    <section className="bg-white">
      {message && (
        <div
          className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
          role="alert"
        >
          <span className="font-medium">Invalid OTP!</span> {message}
        </div>
      )}
      <div className="">
        <form
          id="otp-form"
          className="gap-2 text-[#2F412F] flex justify-center"
        >
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
              className="shadow-xs w-[64px] rounded-lg border border-stroke bg-white p-2 text-center text-2xl font-medium text-gray-5 outline-none sm:text-4xl dark:border-dark-3 dark:bg-white/5"
            />
          ))}
        </form>

        <button
          type="button"
          onClick={VerifyHandler}
          className="text-white mt-10 relative bg-[#2F412F] hover:bg-[#2F412F] font-medium rounded-lg text-sm px-5 py-2.5 mb-2 focus:outline-none"
        >
          <span
            className={`${
              loading ? "opacity-100" : "opacity-0"
            } absolute w-10/12 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10`}
          >
            <CircleLoader color="#ffffff" />
          </span>
          <span className={`${loading ? "opacity-0" : "opacity-100"}`}>
            Verify
          </span>
        </button>

        <div className="mt-4 flex justify-center items-center gap-2">
          {!canResend && (
            <span className="text-gray-500 text-sm">
              Resend available in {timer} seconds{" "}
            </span>
          )}
          <div
            onClick={(e) => (canResend ? handleResend(e) : undefined)}
            className={`border-b-2  text-sm  ${
              canResend ? "cursor-pointer" : ""
            }`}
          >
            Resend OTP
          </div>
        </div>
      </div>
    </section>
  );
}
