import React from 'react'
import App from './App'
import GutHealthSpendProvider from './context/appContext'

const Guts_Main = () => {
  return (
    <GutHealthSpendProvider>{<App/>}</GutHealthSpendProvider>
  )
}

export default Guts_Main