import React from "react";
import "./home.css";
import { useWeightContext } from "../context/appContext";
import Loader from "./loader";

function LastPage({ onFinish }) {
  const { loading } =
    useWeightContext();

  const handleFinish = () => {
    onFinish();
  };

  return (
    <div className="text-center p-5 md:p-10 animate-fadeIn">
      <div className="w-20 h-20 bg-[#2F412F] rounded-full flex items-center justify-center text-white text-4xl mx-auto mb-5">
        ✓
      </div>
      <h2 className="text-lg md:text-2xl font-bold mb-4">
        Your Assessment is Complete!
      </h2>
      <p className="mb-6 text-sm md:text-base">
        We've analyzed your responses and created a personalized health plan.
      </p>
      <button
        onClick={handleFinish}
        // href="thc-result.html"
        className="inline-block text-xs  md:text-base bg-[#2F412F] text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#3D533D] transition-colors"
      >
        View Your Results
      </button>
    </div>
    // <div className="outside-container">
    //   <div className="heading-container">
    //     <div className="heading text-base md:text-lg " >
    //       <p>Thank you for completing the questionnaire!</p>
    //     </div>
    //     <div className="button-container relative w-full  ">
    //       {loading ? (
    //         <div className="mt-10 w-full">
    //           <Loader />
    //         </div>
    //       ) : (
    //         <button onClick={handleFinish} className="relative w-full text-xs md:text-base  ">
    //           View Report
    //         </button>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
}

export default LastPage;
