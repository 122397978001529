import React from "react";

const Loader = () => {
  return (
    <div className="absolute w-full h-full backdrop-blur-lg flex flex-col justify-center items-center z-10">
      <div className="w-24 md:w-32">
        <svg
          version="1.1"
          id="L9"
          // xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          // xml:space="preserve"
        >
          <path
            fill="#2d3d23"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      <div className="font-bold text-sm md:text-2xl lg:text-5xl  text-[#2d3d23] capitalize">
      Customizing your report...
      </div>
    </div>
  );
};

export const CircleLoader = ({ color }) => {
  const newcolor = color ? color : "#2D3D23";
  return (
    <div className="inset-0 flex items-center justify-center absolute top-0">
      <svg version="1.1" id="L4" viewBox="0 0 100 100" className="w-24 h-24">
        <circle fill={newcolor} stroke="none" cx="20" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={newcolor} stroke="none" cx="50" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={newcolor} stroke="none" cx="80" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
