import React, { useState } from "react";
import "./home.css";
import { useWeightContext } from "../context/appContext";
import ContainerWithTitle from "../../../containers/title.container";
import { InputField } from "../App";

function Weight({ onNext, onPrev }) {
  const [weight, setWeight] = useState("");
  const { payload, setPayload, currentPage, setCurrentPage } =
    useWeightContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (weight) {
      onNext(weight);
    }
  };

  const handleWeight = (event) => {
    const input = event.target.value;
    const weight = input.replace(/\D/g, "").slice(0, 3);
    setWeight(weight);
  };

  return (
    <ContainerWithTitle
      title="What is your weight in Kgs?"
      handleNext={handleSubmit}
      handleBack={onPrev}
      isContinueButton={weight ? true : false}
      // Use "&&" for the Switch the next line
      message="We don’t want to intrude, we just need to know, so that we can build a plan that's right for you. 

Thank you for sharing! That’s an important (and hard) first step! "
    >
      <InputField
        label="Weight"
        type="text"
        id="weight"
        name="weight"
        required
        placeholder="Your weight in kilograms"
        value={weight}
        onChange={handleWeight}
        autoFocus={true}
      />
    </ContainerWithTitle>

    // <div className="outside-container">
    //   <div className="question-container">
    //     <form onSubmit={handleSubmit}>
    //       <div className="question-form">
    //         <div className="form-group">
    //           <div className="question">
    //             <p></p>
    //           </div>
    //           <div className="input-container">
    //             <input
    //               type="text"
    //               id="weight"
    //               name="weight"
    //               required
    //               placeholder="Your weight in kilograms"
    //               className="input border-b focus:border-green-900"
    //               value={weight}
    //               onChange={handleWeight}
    //               autoFocus={true}
    //             />
    //           </div>
    //           <div className="button-container">
    //             <button type="submit">Next</button>
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default Weight;
