import React, { useState, useEffect } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";

const Questioneleven = ({ onNext, onPrev }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useGutContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.eating_habits || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage("Please select your eating habits.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="In your family, is their a history of obesity, diabetes, cardiac related ailments or cancer"
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          colorChecked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          colorChecked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Questioneleven;
