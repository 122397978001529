import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

export const useGutContext = () => useContext(AppContext);

const data = {
  name: "",
  phone: "",
  email: "",
  age: "",
  gender: "",
  weight: "",
  height: "",
  exercise: "",
  phyiscal_activity: "",
  concerns: [],
  pass_stoll: "",
  bst: "",
  bothered: "",
  medication: "",
  history_of_obesity: "",
};

const GutHealthSpendProvider = ({ children }) => {
  const [payload, setPayload] = useState(data);
  const [headers, setHeaders] = useState("vitals");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTab, setCurrentTab] = useState("vitals");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPhoneVerified, setIsPhoneVerified] = useState("");

  const [loading, setLoading] = useState(null);
  const [skipquestion, setSkipquestion] = useState({
    question: "How many hours of sleep do you get in a night on average?",
    response: null,
  });

  // useEffect(() => {
  //   setErrorMessage(null);
  //   if (currentPage >= 0 && currentPage <= 6) {
  //     setCurrentTab("vitals");
  //   } else if (currentPage > 6 && currentPage < 9) {
  //     setCurrentTab("goals");
  //   } else if (currentPage >= 9 && currentPage <= 11) {
  //     setCurrentTab("movement&sleep");
  //   } else if (currentPage >= 12) {
  //     setCurrentTab("diet&health");
  //   }
  //   //
  // }, [currentPage]);

  const sessionValue = sessionStorage.getItem("quiz_Id");

  // console.log("currentPage", currentPage);
  // console.log("payload", payload);
  // console.log("currentTab", currentTab);
  // console.warn("setErrorMessage", errorMessage);

  console.log("payload", payload);
  return (
    <AppContext.Provider
      value={{
        payload,
        setPayload,
        currentPage,
        setCurrentPage,
        currentTab,
        setCurrentTab,
        skipquestion,
        setSkipquestion,
        errorMessage,
        setErrorMessage,
        loading,
        setLoading,
        sessionValue,
        isPhoneVerified,
        setIsPhoneVerified,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default GutHealthSpendProvider;
