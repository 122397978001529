import React, { useState, useEffect } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";

const Gender = ({ onNext, onPrev }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useGutContext();
  const [value, setValue] = useState("male");
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    setValue(payload?.gender || "");
  }, [currentPage, payload]);
  const abc = "male";

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value === "other" && otherValue.trim() === "") {
      setErrorMessage("Please specify your gender.");
      return;
    }
    if (value) {
      onNext(value);
    } else {
      setErrorMessage("Select any one before move forward");
    }
  };

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setErrorMessage(null);
    setValue(selectedValue);
    onNext(selectedValue);
    // handleSubmit(event)
    if (selectedValue !== "other") {
      setOtherValue("");
    }
  };

  const handleOtherInputChange = (event) => {
    setOtherValue(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="What is your gender?"
      handleNext={handleSubmit}
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container flex gap-10 w-full  ">
        <RadioInput
          label="Male"
          value="male"
          // checked={value === "male"}
          colorChecked={payload?.gender === "male"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Female"
          value="female"
          // checked={value === "female"}
          colorChecked={payload?.gender === "female"}
          onChange={handleRadioChange}
        />
        {/* <div className="w-full">

        <RadioInput
          label="Other"
          value="other"
          checked={value === "other"}
          onChange={handleRadioChange}
        />
        {value === "other" && (
          <input
            type="text"
            placeholder="Please specify"
            value={otherValue}
            className="input mt-5"
            onChange={handleOtherInputChange}
          />
        )}
        </div> */}
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Gender;
