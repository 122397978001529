import React, { useState, useEffect } from "react";
import { useGutContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";

const QuestionEight = ({ onNext, onPrev }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useGutContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.bst || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="What is the shape of your stool?"
      // description="Bristol Stool Test"
      handleBack={onPrev}
      isContinueButton={false}
    >
      <div className="radio-container flex flex-wrap whitespace-pre">
        <RadioInput
          label={
            <Container
              src="/images/stool/type1.png"
              label="Separate Hard Lumps"
              type="1"
            />
          }
          value="Type 1"
          colorChecked={value === "Type 1"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type2.png"
              label="Lumpy and sausage Like"
              type="2"
            />
          }
          value="Type 2"
          colorChecked={value === "Type 2"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type3.png"
              label="A Sausage shape with cracks in the surface"
              type="3"
            />
          }
          value="Type 3"
          colorChecked={value === "Type 3"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type4.png"
              label="smooth soft sausage or snake"
              type="4"
            />
          }
          value="Type 4"
          colorChecked={value === "Type 4"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type5.png"
              label="soft blobs with clear edges"
              type="5"
            />
          }
          value="Type 5"
          colorChecked={value === "Type 5"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type6.png"
              label="mushy with rugged edges"
              type="6"
            />
          }
          value="Type 6"
          colorChecked={value === "Type 6"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label={
            <Container
              src="/images/stool/type7.png"
              label="liquid consistency with no solid pieces"
              type="7"
            />
          }
          value="Type 7"
          colorChecked={value === "Type 7"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default QuestionEight;

const Container = ({ src, type, label }) => (
  <div className="flex md:flex-row w-full  flex-col gap-6 items-center ">
    <div className="flex justify-center ">
      <img
        src={src}
        // alt="Chat on WhatsApp"
        className="md:max-w-20 border-2 border-white rounded-full max-w-12 max-h-16 h-full object-contain"
      />
    </div>
    <p
      className="break-all w-full text-wrap "
      style={{ wordBreak: "break-word" }}
    >
      <span className="">
        <label className="font-medium text-sm md:text-lg">Type {type}: </label>
        {label}
      </span>
    </p>
  </div>
);
