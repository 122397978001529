// import React from 'react';
// import './questionOne.css';
// import "./home.css";

// function Home({ onNext }) {
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         onNext();
//     };

//     return (
//         <div className='outside-container'>
//             <div className='home-container'>
//                 <div className='heading'>
//                     <h3>Age In Reverse!</h3>
//                 </div>
//                 <div className='subheading'>
//                     <p>Take our short questionnaire to get your personalised gut anti-ageing routine – in less than a minute.</p>
//                 </div>
//                 <div className='button-container'>
//                     <button type="submit" onClick={handleSubmit}>Start Quiz</button>
//                 </div>
//             </div>

//         </div>
//     );
// }

// export default Home;

import React from 'react';
import './home.css';
import { useWeightContext } from '../context/appContext';

function Home({ onNext }) {

    const { payload, setPayload,currentPage, setCurrentPage } = useWeightContext(); 

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = 'Home component data'; // Replace with actual data if needed
        setCurrentPage(currentPage+1)
    };

    return (
        <div className='outside-container'>
            <div className='heading-container  text-sm md:text-xl '>
                <div className=' font-medium text-lg md:text-2xl '>
                    <p>Age In Reverse!</p>
                </div>
                <div className='subheading '>
                    <p>Take our short questionnaire to get your personalised gut anti-ageing routine – in less than a minute.</p>
                </div>
                <div className='button-container text-xs md:text-base'>
                    <button type="submit" onClick={handleSubmit}>Start Quiz</button>
                </div>
            </div>
            
        </div>
    );
}

export default Home;
