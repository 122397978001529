import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

// import HealthSpendProvider from "./context/appContext";
import App from "./pages/weightQuiz/App";
import Routers from "./routers";
import { BrowserRouter } from "react-router-dom";
// import Routers from "";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <HealthSpendProvider> */}
      <Routers />
      {/* </HealthSpendProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);
