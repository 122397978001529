import React from 'react'
import App from './App'
import WeightHealthSpendProvider from './context/appContext'

const Weight_Main = () => {
  return (
    <WeightHealthSpendProvider>{<App/>}</WeightHealthSpendProvider>
  )
}

export default Weight_Main