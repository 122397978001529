import React, { useState, useEffect } from "react";
import { useWeightContext } from "../context/appContext";
import RadioInput from "../../../containers/input.radio";
import ContainerWithTitle from "../../../containers/title.container";
import ButtonContainer from "../../../containers/button";

const Question9 = ({ onNext, onPrev }) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    skipquestion,
    setSkipquestion,
    setErrorMessage,
  } = useWeightContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.medical_condition || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
      // if (val === "Yes") {
      // } else {
      //   setCurrentPage(currentPage + 2);
      // }
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
    onNext(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="Do you have any existing medical condition?"
      handleBack={onPrev}
      isContinueButton={false}
      noteTitle=""
      // Use "&&" for the Switch the next line
      message="You're in good hand &&

We are in this together. We’ve helped 25000+ people lose weight!* && 

*The HealthSpan Co users who lost at least 3% of their original weight as of October 2024. "
    >
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          colorChecked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          colorChecked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      {/* <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer> */}
    </ContainerWithTitle>
  );
};

export default Question9;
